<template>
  <div>
  <div class="personal-info-container "  >
    <b-row class="inpSpace" :class="{
        'inpSpace rtl': lang ==1,
        'inpSpace ltr': lang ==2,
      }">
      <b-col md="12">
        <label class='title'>{{$t('personal_info')}}</label>
      </b-col>
      <b-col md="6">
        <label class="custPortalLbl">{{ $t("name") }}</label>
        <b-form-input
         v-model="user_name"
          id="user-name-input"
          disabled
        ></b-form-input>
      </b-col>

      <b-col md="6">
        <label class="custPortalLbl">{{ $t("account_name") }}</label>
        <b-form-input
          v-model="accountName"
          id="account-name-input"
          disabled
        ></b-form-input>
      </b-col>
      
    </b-row>

   
    <b-row class="inpSpace" :class="{
        'inpSpace rtl': lang ==1,
        'inpSpace ltr': lang ==2,
      }">
     
      <b-col md="6">
        <label class="custPortalLbl">{{ $t("account_reference") }}</label>
        <b-form-input
         v-model="account_reference"
          id="account-reference-input"
          disabled
        ></b-form-input>
      </b-col>

      <b-col md="6">
        <label class="custPortalLbl">{{ $t("phone_number") }}</label>
        <b-form-input
          v-model="phone_number"
          id="phone-number-input"
          disabled
        ></b-form-input>
      </b-col>
    </b-row>

    <b-row class="inpSpace" :class="{
        'inpSpace rtl': lang ==1,
        'inpSpace ltr': lang ==2,
      }" style="display:flex;align-items:center">
      <b-col md="6" v-if="company.changePass">
        <label class="custPortalLbl">{{ $t("email") }}</label>
        <b-form-input
        v-model="email"
          id="Email-input"
          disabled
          type="text"
        ></b-form-input>
      </b-col>

      <b-col md="12" v-else>
        <label class="custPortalLbl">{{ $t("email") }}</label>
        <b-form-input
        v-model="email"
          id="Email-input"
          disabled
          type="text"
        ></b-form-input>
      </b-col>

      <b-col md="6" v-if="company.changePass">
        <button   v-b-modal.passModal id='change-pass-id' class="change-pass-btn"   > {{$t("change_password")}}</button>
      </b-col>
    </b-row>
     <p class="update-note">*{{ $t('update_pesonal_info_note') }} (
        <a  v-for="(number,index) in company.customer_service_numbers" :key="number" :href="`tel:${number}`">
            <span class="mx-2 ">{{number}} </span>
            <span v-if="index != company.customer_service_numbers.length-1">,</span>
        </a>
        <a  v-if="company.addEmailToContactInfoMsg"
            :href="`mailto:${company.customer_service_emails[0]}`"
            class="company-info__block-value"
            > <span class="mx-2 ">,</span>
              <span >
                {{ company.customer_service_emails[0] }}
              </span>
            </a>
            )
      </p>
      
    <hr style="    height: 2px; background: #DFDFDF;">

    <b-row class="inpSpace" :class="{
        'inpSpace rtl': lang ==1,
        'inpSpace ltr': lang ==2,
      }">
       <b-col md="12">
        <label class='title'>{{$t('unit_info')}}</label>
      </b-col>
      <b-col md="6">
        <label class="custPortalLbl">{{ $t(`${company.company_name}_block`) !=`${company.company_name}_block`?$t(`${company.company_name}_block`):$t('block')}}</label>
        <b-form-input
        v-model="block"
          id="block-input"
          disabled
        ></b-form-input>
      </b-col>

       <b-col md="6">
        <label class="custPortalLbl">{{ $t(`${company.company_name}_group`) !=`${company.company_name}_group`?$t(`${company.company_name}_group`):$t('group')}}</label>
        <b-form-input
         v-model="group"
          id="group-input"
          disabled
        ></b-form-input>
      </b-col>
      
    </b-row>

  
    <b-row class="inpSpace" :class="{
        'inpSpace rtl': lang ==1,
        'inpSpace ltr': lang ==2,
      }">
     
      <b-col md="6">
        <label class="custPortalLbl">{{ $t(`${company.company_name}_building`) !=`${company.company_name}_building`?$t(`${company.company_name}_building`):$t('building')}}</label>
        <b-form-input
        v-model="building"
          id="building-input"
          disabled
        ></b-form-input>
      </b-col>
      <b-col md="6">
        <label class="custPortalLbl">{{ $t(`${company.company_name}_unit`) !=`${company.company_name}_unit`?$t(`${company.company_name}_unit`):$t('unit')}}</label>
        <b-form-input
         v-model="unit"
          id="unit-input"
          disabled
        ></b-form-input>
      </b-col>
    </b-row>
  <!-- here passowrd popup -->
  
 <!-- here passowrd popup -->



  </div>


  <b-modal
      no-close-on-esc
      id="passModal"
      size="sm"
      @hide="reset" 
      :header-class="{
        'right-close': lang == 1,
        'left-close': lang == 2,}">
        <h3 class="pass-modal-title"  :class="{ rtl: lang == 1, ltr: lang == 2 }">{{ $t("change_password") }}</h3>
    
      <form
        :class="{ rtl: lang == 1, ltr: lang == 2 }">
        <Feedback
          v-if="feedback.show"
          :state="feedback.state"
          :message="feedback.message"
          :lang="lang"
          @unmountFeedComponent="feedback.show=false"
        />
        <b-row
      v-if="company.changePass"
      :class="{
        'inpSpace rtl': lang === 1,
        'inpSpace ltr': lang === 2,
      }"
    >
      <b-col md="12">
        <b-form-group
          class="custPortalLbl"
          id="form-label-current_password"
          :label="$t('current_password')"
          label-for="form-input-current_password"
        >
          <b-form-input
            v-if="show_current_pass"
            v-model="form.current_password"
            :state="validation.current_password"
            aria-describedby="current_password-live-feedback"
            id="form-input-current_password"
            placeholder=" ********"
          >  
          </b-form-input>
          <b-form-input
            v-else
            v-model="form.current_password"
            :state="validation.current_password"
            aria-describedby="current_password-live-feedback"
            id="form-input-current_password"
            placeholder=" ********"
            type="password"
          ></b-form-input>
          <div class="show-hide-pass">
            <i  @click="toggleShow(1)" :class="{ 'pylon-icon-Eye-Blocked': show_current_pass, 'pylon-icon-Eye': !show_current_pass }"></i>
          </div>
          <b-form-invalid-feedback id="current_password-live-feedback">
            {{err_msgs.current_password }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

    
    </b-row>

    <b-row
      v-if="company.changePass"
      :class="{
        'inpSpace rtl': lang === 1,
        'inpSpace ltr': lang === 2,
      }"
    >
     <b-col md="12">
        <b-form-group
          class="custPortalLbl"
          id="form-label-password"
          :label="$t('new_password')"
          label-for="form-input-password"
        >
          <b-form-input
            v-if="show_new_pass"
            v-model="form.new_password"
            :state="validation.password"
            aria-describedby="password-live-feedback"
            id="form-input-password"
            placeholder=" ********"
          ></b-form-input>
          <b-form-input
            v-else
            v-model="form.new_password"
            :state="validation.password"
            aria-describedby="password-live-feedback"
            id="form-input-password"
            placeholder=" ********"
            type="password"
          ></b-form-input>
          <div class="show-hide-pass">
            <i  @click="toggleShow(2)" :class="{ 'pylon-icon-Eye-Blocked': show_new_pass, 'pylon-icon-Eye': !show_new_pass }"></i>
          </div>
          <b-form-invalid-feedback id="re-password-live-feedback">
            {{ err_msgs.password }}
          </b-form-invalid-feedback>
          <ul style="list-style-type:none;padding:0px;margin-top: 8px;color: #767676;font-family: Montserrat;font-size: 14px;font-weight: 400;">
            <li  v-for="(item,index) in $t('password_validation')" :key="index" :ref="`pass-vaildation${index}`">
            <span  style="display:flex;align-items:center"><span v-if="!pass_validation.covered[index]" class="empty-circle"></span><i v-else class="pylon-icon-Circle-Checked"></i> {{item}}</span>
           </li>     
          </ul>
          <!-- <b-form-invalid-feedback id="password-live-feedback">
            <ul style="list-style-type:none;padding:0px">
            <li  v-for="(item,index) in $t('password_validation')" :key="index">
            <span><span v-if="not_covered" class="empty-circle"></span><i v-else class="pylon-icon-Circle-Checked"></i> {{item}}</span>
           </li>     
          </ul>
          </b-form-invalid-feedback> -->
          
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group
          class="custPortalLbl"
          id="form-label-repassword"
          :label="$t('confirm_password')"
          label-for="form-input-repassword"
        >
          <b-form-input
            v-if="show_confirmed_pass"
            v-model="form.new_password_confirmation"
           :state="validation.new_password_confirmation"
            aria-describedby="re-password-live-feedback"
            placeholder="********"
            id="form-input-repassword"
          ></b-form-input>
          <b-form-input
           v-else
            v-model="form.new_password_confirmation"
           :state="validation.new_password_confirmation"
            aria-describedby="re-password-live-feedback"
            type="password"
            placeholder="********"
            id="form-input-repassword"
          ></b-form-input>
          <div class="show-hide-pass">
            <i  @click="toggleShow(3)" :class="{ 'pylon-icon-Eye-Blocked': show_confirmed_pass, 'pylon-icon-Eye': !show_confirmed_pass }"></i>
          </div>
          <b-form-invalid-feedback id="re-password-live-feedback">
            {{ err_msgs.new_password_confirmation }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <div  v-if="company.changePass" style="display:flex;margin-top:32px;justify-content:space-between;width:100%">
    
      <b-button
          id="change-pass-cancel-btn"
          @click="cancel()"
          class="updateButton cancel"
          style="text-align: center"
          >{{ $t("cancel") }}</b-button
        >

        <b-button
          id="change-pass-save-btn"
          @click="handleSubmit()"
          class="updateButton btn-color"
          style="text-align: center"
          :disabled="disabled_Save_btn"
          >{{ $t("save") }}</b-button
        >
    </div>
    
      </form>

  </b-modal>
  <b-modal
      no-close-on-esc
      id="successfullyMoadl"
      size="sm"
     
      :header-class="{
        'right-close': lang == 1,
        'left-close': lang == 2,}">
    
      <div class="successfully-pop-up">
        <i class="pylon-icon-Circle-Checked" style="color:#0BBF04"></i>
        <p>{{$t('success_password_msg') }}</p>
      </div>

  </b-modal>
    </div>
</template>

<script>
import services from "../../services/profile";
import Authservices from '../../services/authentication'
import Feedback from "../Feedback.vue";

export default {
  components: {
    Feedback,
  },
  props:["company",'meter_id',"lang","propsObj"],
  data() {
    return {
      user_name:'',
      accountName: '',
      account_reference:'',
      phone_number:"",
      email:'',
      building:'',
      block:'',
      unit:"",
      group:"",
      pass_validation:{
        regexExpressions:[/^.{8,20}$/,/^(?=.*?[a-z]).*/,/^(?=.*?[A-Z]).*/,/^(?=.*?[0-9]).*/,/^(?=.*?[-#?!$%^&*@]).*/],
        covered:[false,false,false,false,false]
      }
      ,
      form: {
        new_password: "",
        new_password_confirmation: "",
        current_password:"",
      },
      validation: {
        password: null,
        new_password_confirmation: null,
        current_password:null,
      },
      err_msgs:{
        password: '',
        new_password_confirmation: '',
        current_password:'',
      },
      feedback: {
        state: 0,
        message: "",
        show: false,
      },
      show_current_pass:false,
      show_confirmed_pass:false,
      show_new_pass:false,
    };
  },
  computed:{
    disabled_Save_btn(){
      return !(this.validateCurrentPass()&&this.validatePassword()&&this.validateRePassword());
    }
  },
  watch: {
    propsObj(){
      this.initialize();
    },
    "form.new_password":function(){
     
      
      // let UPPER=/^(?=.*?[A-Z]).*/;
      // let LOWER=/^(?=.*?[a-z]).*/;
      // let SPECIAL=/^(?=.*?[-#?!$%^&*@]).*/;
      // let RANGE=/^.{8,20}$/;
      // let DIGITS=/^(?=.*?[0-9]).*/;

      for(let i=0;i<this.pass_validation.regexExpressions.length;i++){
        if( this.pass_validation.regexExpressions[i].test(this.form.new_password)){
          this.$refs[`pass-vaildation${i}`][0].classList.add('covered');
          this.pass_validation.covered[i]=true;
        }
        else{
          this.$refs[`pass-vaildation${i}`][0].classList.remove('covered');
          this.pass_validation.covered[i]=false;
        }

        this.validation.password=this.validatePassword();
        if(this.form.new_password!=='' &&this.form.new_password!==null && this.form.new_password!=this.form.current_password){
          this.validation.password=null;
        }
        this.validation.new_password_confirmation=this.validateRePassword();
      
      
    }
    },
    "form.current_password":function(){
      this.validation.current_password=this.validateCurrentPass();
    },
    "form.new_password_confirmation":function(){
      this.validation.new_password_confirmation=this.validateRePassword();

       },

  },
  methods: {
    ReturnUserInfo(){
      let results =this.propsObj.userInfo.filter(obj => {
      return obj.req_pk ===this.meter_id;
      });

        this.phone_number=this.propsObj.meterInfo.mobile;
        this.email=this.propsObj.meterInfo.email;
        this.unit=results[0].unit;
        this.building=results[0].building;
        this.block=results[0].block_code;
        this.group=results[0].group_code;  
        this.account_reference=results[0].account_reference;
    },
    initialize(){
      this.ReturnUserInfo()
      this.getProfileData();
    },
    getProfileData() {
      services.getUserInfo()
      .then(data => {
        // if(data.length == 0 )
        //   throw Error(1);
        // else 
          return data[0];
      })
      .then(data => {
        // this.form.email = data.notification_email,
        // this.form.mobile = data.notification_mobile
        this.accountName = data.email;
        this.user_name=data.user_name;
        this.$emit('update-user-name', data.user_name);
      })
      .catch(err => {
      })
    },

    handleSubmit() {
        services.updatePersonalInfo(this.form).then((res) => {
          if (res=== true) {
            this.$bvModal.hide('passModal'); 
            this.$bvModal.show('successfullyMoadl');
            setTimeout(() => (Authservices.endSession()), 1000);

          }
        })
        .catch((err)=>{
            if(err.cause==-214){
              this.validation.current_password=false;
              this.err_msgs.current_password=err.message;
            }
            else if(err.cause==-98){
              this.validation.password=false;
              this.validation.new_password_confirmation=false;
              this.err_msgs.password=err.message;
              this.err_msgs.new_password_confirmation='';
            }
            else{
              this.feedback.state = 2;
              this.feedback.message =err.message;
              this.feedback.show = true;
            }
          });
    },

    // validateEmail() {
    //   const EMAIL_REGEX = /\S+@\S+/;
    //   return EMAIL_REGEX.test(this.form.email);
    // },

    // validateMobile() {
    //   const MOBILE_REGEX = /^[0-9]{11}$/;
    //   return MOBILE_REGEX.test(this.form.mobile);
    // },

    validateCurrentPass(){
     this.err_msgs.current_password=this.$t("enter_current_pass")
     return this.form.current_password != null && this.form.current_password!='';
    },
    validatePassword() {
      const PASSWORD_REGEX =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!$%^&*-@]).{8,20}$/;

      if(this.form.new_password=='' ||this.form.new_password==null){
        this.err_msgs.password=this.$t("new_password_err");
        return false;
      }
      else if(this.form.new_password!=''&& this.form.new_password!=null && this.form.new_password==this.form.current_password){
        this.err_msgs.password=this.$t("new_curr_pass_are_qual");
        return false;
      }
      else{
        this.err_msgs.password='';
        return PASSWORD_REGEX.test(this.form.new_password);
      }
    
    },

    validateRePassword() {
        this.err_msgs.new_password_confirmation=(this.form.new_password_confirmation==null || this.form.new_password_confirmation=='') && this.form.new_password==''
                                                ?this.$t('confirm_password')
                                                :this.$t("no_matching_pass");
      return (this.form.new_password === this.form.new_password_confirmation) && this.form.new_password_confirmation!=null &&this.form.new_password_confirmation!='';
    },
    cancel(){
      this.$bvModal.hide('passModal');
    },
    reset(){
        this.form.new_password= "",
        this.form.new_password_confirmation= "",
        this.form.current_password="",
        this.validation.password=null,
        this.validation.new_password_confirmation= null;
        this.validation.current_password=null;
        this.feedback.show = false;
      
    },
    toggleShow(ele){
      if(ele==1) this.show_current_pass=!this.show_current_pass;
      else if(ele==2)  this.show_new_pass=!this.show_new_pass;
      else  this.show_confirmed_pass=!this.show_confirmed_pass;
    }
  },
};
</script>
<style>
.personal-info-container .form-control {
    border: 1px solid #AAAAB2;
    color: #767676;
    background: #E0E0E0;

}
.personal-info-container .row{
  margin-bottom: 16px;
}
.custPortalLbl {
  font-weight: 600 !important;
  font-size: 16px;
  color: #2C3E50;
}

/* .personal-info-container label{
  color:#2c3e50;
} */
.change-pass-btn{
background-color:transparent;
border:0px;
border-bottom:1px solid var(--main-color);
color:var(--main-color);
font-weight: 500 !important;
font-size: 16px;
margin-top: 20px;
}
.pass-modal-title.ltr{
  font-family: 'Montserrat' !important;
}
.pass-modal-title{
height: 40px;
font-weight: 600 !important;
font-size: 24px;
color: #2C3E50;
line-height: 40px;
margin-bottom: 24px;

}
#passModal___BV_modal_body_{
  padding-top:0px !important;
}
.updateButton{
  width:49%;
  padding:12px;
  font-weight: 600 !important;
font-size: 14px;
}
.updateButton.cancel{
  color:var(--main-color);
  background-color:white;
  border-color: var(--main-color);
}
#successfullyMoadl___BV_modal_content_.modal-content{
  width: 488px;
  height: 248px;
  margin:auto;
}
@media (max-width:600px){
  #successfullyMoadl___BV_modal_content_.modal-content{
    width:90%;
}
}
.successfully-pop-up{
  text-align: center;
}
.successfully-pop-up i{
  color: rgb(11, 191, 4);
    font-size: 65px;
}
.successfully-pop-up P{
  padding-top: 24px;
    font-weight: 700 !important;
    font-size: 20px;
    color: #111C4E;
}
.update-note,.update-note> a{
  color:#D83B57;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
span.empty-circle{
  width: 13px;
  height: 13px;
  display: block;
  border: 1.4px solid black;
  border-radius: 50% !important;
  border:1.6px solid #767676;
  margin: 0px 5.5px;

}
.covered{
  color:#088C03;
}
.covered i{
  margin: 0px 4px;
}
.custPortalLbl > div{
  position: relative;
  
}
.custPortalLbl .show-hide-pass{
  position: absolute;
  top: 26%;
  left:12px;
  /* color: #AAAAB3; */
}
.ltr .custPortalLbl .show-hide-pass{
left:unset;
right:12px;
}
.ltr .custPortalLbl .form-control.is-valid + .show-hide-pass,.ltr .custPortalLbl .form-control.is-invalid +.show-hide-pass{
  right:35px;
  left:unset;

}
.custPortalLbl .form-control.is-invalid +.show-hide-pass{
  top:18%;
}
#form-label-password.custPortalLbl .show-hide-pass{
top:7%;
}

</style>
