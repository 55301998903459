<template >
    <div  style="min-height: 100vh;margin:0px 40px" class="mb-3">
    <div>
        <p class="faq-header" :class="{'rtl': lang==1, 'ltr': lang==2}">{{$t("faq_header")}}</p>
        <FaqChild :lang='lang' :company='company'/>       
    </div>
    </div>
</template>
  
<script>
import FaqChild from "@/components/FAQ/FaqChild.vue";
  export default {
    props: ["lang", "company"],
      components: {
    FaqChild,
  },
    data() {
      return {
      }
    },
   watch:{
     
   },
    methods: {

    }, 
  };
  </script>
  
  <style scoped>
.faq-header{
  font-weight: 700 !important;
  font-size: 32px;
  line-height: 39px;
  color: #002369;
  margin-top: 48px;
  max-width: 856px;
  margin: 0px auto;
  margin-top: 32px;
  padding: 0px;
  margin-bottom: 44px;
}

@media(max-width:600px){
  .faq-header{
  font-size: 24px;
}
}



  </style>