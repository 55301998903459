<template >
    <div >
    <div class="search-container d-flex m-auto" :class="{'rtl': lang==1, 'ltr': lang==2}">
          <b-form-input
          v-model="searchInput"
          id="search-input"  
          :placeholder="$t('search_placeholder')"      
          ></b-form-input>
          <i :class="{'pylon-icon-Search-Arabic': lang==1, 'pylon-icon-Search-English': lang==2 }"></i>
          <i v-show="show_closing" class="pylon-icon-Close closing" @click="resetSearch(false)"></i>
          <button class="search-btn" :disabled="emptySearchValue" @click="search()">
            {{ $t('search') }}
          </button>
        </div>
       
        <div class="content">
            <div v-for="(item, index) in data" :key="index" class="questions-block" :class="{'rtl': lang==1, 'ltr': lang==2}">
                    <p @click="showAns(index)" class="question"> 
                            <span :ref="`question${index}`">{{item.question}}</span>
                            <i v-if="item.show" class="pylon-icon-Arrow-Up"></i>
                            <i v-if="item.show==false" class="pylon-icon-Arrow-Down"></i>
                    </p>
              

                     <div class="parent" :ref="`ans${index}`">
                      <div  v-for="(ans, i) in item.ans" :key="i" v-show="item.show" class="answer"  > 
                       <span class="paragraph"> {{ans.paragraph}} </span>
                       <ul class="steps">
                        <li v-for="(step,index) in ans.steps" :key="index">{{step}}</li>
                       </ul>
                       
                    </div>
                     </div>
                    
            </div>
        </div>
    </div>
</template>
  
<script>
const faqData =require( `@/faq_question/faq_${process.env.VUE_APP_CLIENTNAME}.json`);

         
  export default {
    props: ["lang", "company"],
    
    data() {
      return {
        show_d:false,
        data:this.lang==1 || this.lang==2 && !faqData[1].length? faqData[0]:faqData[1],
        searchInput:"",
        show_closing:false,
        emptySearchValue:true,

      }
    },
   watch:{
      searchInput(newInput){
        if(newInput==''){
          this.show_closing=false;
          this.emptySearchValue=true
          this.resetSearch(false);
        }
        else{
          this.show_closing=true;
          this.emptySearchValue=false;
        }
       
      }
   },
    methods: {
    
    showAns(index){    
     if(this.data[index].show==false){ this.data[index].show=true}
     else {this.data[index].show=false}
    },
    search(){
      this.resetSearch(true);
      let searchArray=this.searchInput.split(" ");
      
      this.data.map((ele,index)=>{
      for(let i=0;i<searchArray.length;i++){
        let searchReg=new RegExp(`${searchArray[i]}`,'gi');
       
        if(searchReg.test(ele.question)){
          ele.show=true;
          this.$refs[`question${index}`][0].innerHTML = this.$refs[`question${index}`][0].innerHTML.replace(searchReg,`<span style="  background: yellow;font-size: 18px;font-weight: 600 !important;">${searchArray[i]}</span>`)
        }
  
        for(let k=0;k<ele.ans.length;k++){
          if(searchReg.test(ele.ans[k].paragraph)){
            ele.show=true;
            this.$refs[`ans${index}`][0].childNodes[k].childNodes[0].innerHTML = this.$refs[`ans${index}`][0].childNodes[k].childNodes[0].innerHTML.replace(searchReg,`<span style="  background: yellow;font-size: 16px;font-weight: 500 !important;">${searchArray[i]}</span>`)
           }
           for(let m=0;m<ele.ans[k].steps.length;m++){
              if(searchReg.test(ele.ans[k].steps[m])){
              ele.show=true;
              this.$refs[`ans${index}`][0].childNodes[k].childNodes[1].childNodes[m].innerHTML =this.$refs[`ans${index}`][0].childNodes[k].childNodes[1].childNodes[m].innerHTML.replace(searchReg,`<span style="  background: yellow;font-size: 16px;font-weight: 500 !important;">${searchArray[i]}</span>`)
            }
           }
      
        }
        
      } 
     }); 
    },
    resetSearch(searchBtnCliked){
      this.searchInput=searchBtnCliked?this.searchInput:'';
      let questionElemes=Array.from(document.querySelectorAll('.question > span'));
      let answerElemes=Array.from(document.querySelectorAll('.parent'));
      questionElemes.map((ele,index)=>{
        ele.innerHTML=ele.innerHTML.replace(ele.innerHTML,this.data[index].question);    
        for(let i=0;i< answerElemes[index].childNodes.length;i++){
          answerElemes[index].childNodes[i].childNodes[0].innerHTML= answerElemes[index].childNodes[i].childNodes[0].innerHTML
          .replace(answerElemes[index].childNodes[i].childNodes[0].innerHTML,this.data[index].ans[i].paragraph)

          for(let j=0;j<answerElemes[index].childNodes[i].childNodes[1].childNodes.length;j++){
            answerElemes[index].childNodes[i].childNodes[1].childNodes[j].innerHTML= answerElemes[index].childNodes[i].childNodes[1].childNodes[j].innerHTML
          .replace(answerElemes[index].childNodes[i].childNodes[1].childNodes[j].innerHTML,this.data[index].ans[i].steps[j])

          }
        }
       this.data[index].show=false;
    })     

}
    
    }, 
  };
  </script>
  
  <style scoped>
.questions-block{
  /* width: 55%; */
  /* margin: auto; */
  padding:25px 0px;
  border-bottom: 1px solid #AAAAB2;

}
.questions-block .question {
  font-weight: 600 !important;
  font-size: 18px;
  color: var(--secondary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0px;
  position: relative;
  cursor: pointer;
}
.questions-block .question span{
font-weight: 600 !important;
  font-size: 18px;
  color: #111C4E;
  line-height: 24px;
}
.questions-block .question i{
  font-weight: 600 !important;
  font-size: 18px;
  position: absolute;
  top: 15%;
  left: 100%;
}
.questions-block.rtl .question i{
  right:100%;
  left: 0;
}
.questions-block .answer{
  font-weight: 500 !important;
  font-size: 16px;
  color: #4F4D61;
  line-height: 24px;
  margin-bottom:0px;
  margin-top: 16px;
}

.search-container{
  max-width: 856px;
  position: relative;
  padding: 0px;
  margin-bottom: 16px !important;
}

.search-container.rtl{
  direction:rtl;
}
.search-container #search-input{
  width: 288px;
  max-width: 100%;
  height: 40px;
  color: #262338;
  background: #FFFFFF;
  border: 1px solid #AAAAB3;
  border-radius: 4px;
  padding: 12px 44px;

}
.search-container i{
  position: absolute;
  top: 25%;
  left: 12px;
  color: #AAAAB2;
  font-size: 20px;
  font-weight: 600;
  
}
.search-container i.closing{
  left: 258px;
}
.search-container.rtl i{
  left:unset;
  right:12px;
}
.search-container.rtl i.closing{
  right:258px;
}
.search-container .search-btn{
  width: 82px;
  height: 40px;
  background: #1A3978;
  border-color: #1A3978;
  border-radius: 4px;
  color: white;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 10px;
  margin: 0px 16px;
  padding: 12px 16px;
  border-color: transparent;
}
.search-container.rtl .search-btn{
  font-size: 18px;
  padding-right:23px ;
  padding-top:10px;
}
.search-container button.search-btn[disabled]{
   background-color: #AAAAB3;
}
.content{
  max-width: 856px;
  margin:0px auto;
  padding:0px
  }
  @media (max-width:400px) {
      .search-container{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        row-gap: 8px;
      }
      .search-container .search-btn{
        margin:0px;
      }
      .search-container i{
        top:12%;
      }
    }
  </style>