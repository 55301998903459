<template>
  <div :class="{ rtl: lang ==1, ltr: lang ==2}">
    <div class="relative invoice-header__download-button__container ">
      <button 
        @click="downloadInvoice()"
        class="invoice-header__download-button"
        :class="{'invoice-header__download-button_position-ar': lang==1, 'invoice-header__download-button_position-en': lang==2}">
        <i class="pylon-icon-Download"></i>
        <span> {{ $t("Download") }}</span>
      </button>
    </div>
    <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        filename="Invoice"
        :paginate-elements-by-height="1400"
        pdf-content-width=""
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            
            <div class="card invoice" :class="{ rtl: lang ==1, ltr: lang ==2}">
              <div class="invoice-header">

                <span v-if="receipt_services" class="invoice-header__title"> {{ $t("payment_receipt") }} </span>
                <span v-else class="invoice-header__title"> {{ $t("payment_voucher") }} </span>

                <div class="logo-container">
                  <img v-if="lang==1" :src="require(`@/Projects/${company.logo_path.dark_logo_ar}`)" alt="Mostakbal city logo" width="150px">
                  <img v-else :src="require(`@/Projects/${company.logo_path.dark_logo_en}`)" alt="Mostakbal city logo" width="150px">
                </div>
            </div>

            <!-- ================================================================================= -->

            <div class="invoice-section customer">
                <span> {{ $t("hello") }} {{ transactionData.customer_name }}</span>
                <p v-if="receipt_services">
                  {{$t('payment_receipt')}}
                  {{$t('number')}}
                  <b class="invoice-section__row-title">#{{ transactionData.id }}</b>
                  {{$t('on')}}
                  {{transactionData.creationTime}}
                </p>
                <p v-else>
                  {{ $t("your_transaction") }}
                  {{ $t("number") }}
                  <b class="invoice-section__row-title">#{{ transactionData.id }}</b>
                  {{$t('was')}}
                  {{$t('successfull')}}
                  {{$t('on')}}
                  {{transactionData.creationTime}}
                </p>

                <div class="invoice-section__row">
                <span class="invoice-section__row-title">
                    {{ $t("amount_payed") }}</span
                >
                <span class="invoice-section__row-value"
                    >{{ transactionData.amount }} {{ $t("egp") }}</span
                >
                </div>
            </div>

            <!-- ================================================================================= -->

            <div class="invoice-section service">
                <p class="invoice-section__title">{{ $t("customer_code")}}</p>

                
                <div class="invoice-section__row">
                  <span class="invoice-section__row-title">{{transactionData.account_reference}}</span>
                </div>
            </div>

            <!-- ================================================================================= -->
            
            <div v-if="transactionData.charge_token" class="invoice-section service">
                <p class="invoice-section__title">{{ $t("charge_token")}}</p>

                
                <div class="invoice-section__row">
                  <span class="invoice-section__row-title">{{transactionData.charge_token}}</span>
                </div>
            </div>

            <!-- ================================================================================= -->

            <div class="invoice-section transaction">
                <p class="invoice-section__title">{{ $t("payment_details_header") }}</p>

                <div v-if="receipt_services">
                  <table>
                    <tr>
                      <th>{{$t('service')}}</th>
                      <th>{{$t('the_price')}}</th>
                      <th>{{$t('the_tax')}}</th>
                      <th>{{$t('total')}}</th>
                    </tr>
                    <tr v-for="(service, index) in receipt_services" :key="index">
                      <td>{{service.name}}</td>
                      <td>{{service.value}}</td>
                      <td>{{service.tax}}</td>
                      <td>{{(Number(service.value) + Number(service.tax))}}</td>
                    </tr>
                  </table>
                </div>


                <div v-else class="invoice-section__row">
                  <span class="invoice-section__row-title"> {{ $t("service_value") }}</span>
                  <span class="invoice-section__row-value">{{ parseFloat(+transactionData.total_without_tax).toFixed(2)}} {{$t('egp')}}</span>
                </div>
                        
                <div class="invoice-section__row">
                  <span class="invoice-section__row-title"> {{ $t("collection_fees") }}</span>
                  <span class="invoice-section__row-value">{{ collection_fees}} {{$t('egp')}}</span>
                </div>
                
                <div class="invoice-section__row">
                  <span class="invoice-section__row-title"> {{ $t("total_amount") }}</span>
                  <span class="invoice-section__row-value">{{ parseFloat(+transactionData.amount).toFixed(2)}} {{$t('egp')}}</span>
                </div>

            </div>


            <!-- ================================================================================= -->

            <div class="additional-info">
                <div class="additional-info__customer-support">
                <p>
                    {{ $t("invoice_additional_message") }}
                    <b class="invoice-section__row-title">#{{ transactionData.id }}</b
                    >.
                </p>
                </div>

                <div class="additional-info__payment-method" v-if="transactionData.payment_method">
                  <p>{{ $t("invoice_powered_by") }}</p>
                  <img :src="require(`@/assets/${company.Invoice_img}`)">
                
                  <!-- <img
                      v-if="company.nbe === true"
                      src="../../assets/National_Bank_of_Egypt_Logo.png"
                  />
                  <img
                      v-if="company.paytabs === true"
                      src="../../assets/PayTabs-Logos_English.webp"
                  /> -->
                </div>
            </div>

            </div>

        </section>

    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  props: ["lang", "company", "transactionData", "receipt_services"],
  components: {
    VueHtml2pdf
  },
  data() {
    return {
      
    }
  },
  computed: {
    collection_fees() {
      return (Number(this.transactionData.amount) - Number(this.transactionData.total_without_tax)).toFixed(2);
    }
  },
  methods: {
    downloadInvoice() {
      this.$refs.html2Pdf.generatePdf()
    }
  }
};
</script>

<style scoped>

.invoice-header__download-button__container{
  padding:0px 20px;
}
.invoice {
  max-width: 600px;
  padding: 20px;
  margin: 40px auto;
  margin-top:10px;
  border-radius: 8px;
}

.invoice-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}

.invoice-header__title {
  font-weight: 600 !important;
  font-size: 32px;
  color: #262338;
}

.logo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* background-color: var(--secondary-color); */
}

.invoice-header__download-button__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0px;
  max-width: 600px;
  margin: 0 auto;
}

.invoice-header__download-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0;
  background-color: white;
  border: 0;
  color: var(--main-color);
  margin-top:24px;
}


.invoice-section {
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.invoice-section__title {
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 27px;
  color: #262338;
}

.invoice-section__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 16px 0px;
}

.invoice-section__row-title,
.invoice-section__row-value {
  font-weight: 700 !important;
  font-size: 16px;
  color: #4f4d61;
}

.invoice-section__row-value {
  text-align: end;
}

/* ================================================================================================== */

.customer {
  color: #4f4d61;
}

.customer span {
  font-weight: 600 !important;
  font-size: 16px;
}

.summary-description {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
  color: #4f4d61;
}

/* ================================================================================================== */
.additional-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.additional-info__customer-support {
  width: 65%;
}

.additional-info__payment-method {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.additional-info__payment-method img {
  width: 100px;
}

.additional-info__payment-method p {
  font-weight: 400 !important;
  font-size: 14px;
  text-align: center;
  color: #4f4d61;
  margin-bottom: 8px;
}
/* ================================================================================================== */

@media (max-width: 575px) {
  .invoice, .invoice-header__download-button__container {
    width: 90%;
  }
  .invoice-header__title{
    font-size: 20px;
  }
  th,td{
    padding:5px;
  }
}
th,td{
  background: #E0E0E0;
  color:  #4F4D61;
  font-size: 14px;
  font-weight: 700;
  text-align: center;

}
td{
font-weight: 500;
background: #fff;
}
td p{
  text-align: center;
  margin-bottom: 0px;
}
.text-secondary {
  color: #AAAAB2;
  font-size: 12px;
  font-weight: 500;
}
table, td, th{
  border: 1px solid #E0E0E0 !important;
}
</style>
