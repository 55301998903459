<template >
  <div class="login" style="min-height: 100vh" :class="{'rtl d-flex flex-column': lang==1, 'ltr d-flex flex-column': lang==2}">
    <Feedback v-if="show_recharge_error" :state="state" :message="recharge_error_msg"  :query_found="true" @unmountFeedComponent="show_recharge_error=false" class="info-nofication"/>
    <NavBar  :company="company" :lang="lang"/>
    <div class="colored-rectangle"></div>

    <div class="login-container w-100" >
      
   
      <div  class="card rounded-lg ">
  
        <!-- <div >
          <h2 class="header" :class="{'not-active': (loginform===false && company.fastRecharge===true)}" @click="showRechargeLogin(1)">{{$t('login')}}</h2>
           <span class="or mx-3"  v-if="company.fastRecharge" >{{$t("or")}}</span><h2 class="header" 
            :class="{'not-active': rechargeComponent===false}" @click="showRechargeLogin(2)" v-if="company.fastRecharge">{{$t("recharge_meter")}}</h2>
        </div> -->
        <LoadingSpinner v-if="loading" color="white" scale="0.4" />
        <div class="tabsss" >
              <div @click="showRechargeLogin(1)" 
                  :class="{'clicked': loginform === true && company.fastRecharge, 'noFastRecharge' : company.fastRecharge ===false}" >
               <span>
                    {{$t('login')}}
                </span>
              </div>
                <div v-if="company.fastRecharge" 
                  @click="showRechargeLogin(2)" 
                  :class="{'clicked': rechargeComponent === true}">
                  <span>
                  {{$t("recharge_meter")}}
                </span>
              </div>
        </div>

            
      <div  v-if="loginform">
        <Feedback v-if="error" :state="2" :message="errorMessage" @unmountFeedComponent="error=false"/> 

        <b-form>
            <b-form-group
              style="margin-bottom:16px"
              id="input-group-1"         
            >

            <label for="customer-email">{{$t('username')}}</label>
            <b-form-input
              id="customer-email"
              style="direction: ltr"
              v-model="form.email"
              type="text"
              :placeholder="$t('username_placeholder')"
              required
              v-on:keyup.enter="enterEvent"
            ></b-form-input>

            </b-form-group>

            <b-form-group
              style="margin-bottom: 59px;"
              id="input-group-2" >
              <label for="password">{{$t('password')}}</label>
              <b-form-input
                id="password"
                v-model="form.password"
                type="password"
                :placeholder="$t('password_placeholder')"
                required
                v-on:keyup.enter="enterEvent">
              </b-form-input>

              <p class="description"> 
                <span v-if="lang==2">{{company.first_login_pass_msg_en}} </span> 
                <span v-if="lang==1">{{company.first_login_pass_msg_ar}} </span>
                </p>
            </b-form-group>

            <b-button
            id="login-button"
            :disabled="disableLogin"
            @click="handleLogin"
              class="w-100 btn-color"
              style="text-align: center;margin-bottom:20px"
              >
              {{loginButtonText}} 
              </b-button
            >
        </b-form>

        
        <a v-if="company.forgot_pass" v-b-modal.resetModal class="forgot-pass d-flex justify-content-center ">{{$t("forgot_pass")}}</a>
      </div>
      
      <FastRecharge v-if="rechargeComponent" :lang="lang" :company="company"/>
         
      </div>

    </div>

    <b-modal
      no-close-on-esc
      id="resetModal"
      size="sm"
     
      :header-class="{
        'right-close': lang == 1,
        'left-close': lang == 2,}">
    
      <div class="reset-pop-up">
        <div class="phone-icon"></div>
        <p>{{$t("forget_msg") }}</p>
        <a  v-for="(number,index) in company.customer_service_numbers" :key="number" :href="`tel:${number}`">
          <span class="mx-2 ">{{number}} </span>
          <span v-if="index != company.customer_service_numbers.length-1">,</span>
        </a>
      </div>

  </b-modal>

    
    <!-- <CompanyInfo v-if="company.login_footer" :company="company" :lang="lang"/> -->
    
    <div class="d-flex justify-content-center" style="margin-bottom: 240px;align-items: center;margin-top: 24px;">
    <p style="margin-bottom: 0px;font-size: 12px;color: #AAAAB2;">{{$t('powered_by')}}</p>
      <img  alt="pylon company logo"
          style="display: block;
              width: 100px;
              height: 40px;"
          src="../assets/pylon.png"> 
    </div>

    <Footer :isApply="noNavInFooter" :company="company" :lang="lang"/>
  </div>
</template>

<script>
import NavBar from "@/components/Login/LoginNavbar.vue";
import CompanyInfo from "@/components/Login/CompanyInfo.vue";
import FastRecharge from '@/components/Recharge/FastRecharge.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import Feedback from "@/components/Feedback.vue";
import Footer from '../components/Footer.vue';

import services,{ AuthenticationError, LockoutError} from "@/services/authentication";

import { getCookie } from '../utils';

export default {
  components: {
    NavBar,
    LoadingSpinner,
    FastRecharge,
    CompanyInfo,
    Feedback,
    Footer,
  },
  props: ["lang", "company"],
  data() {
    return {
      rechargeComponent:false,
      loginform:true,
      npe_checked: true,
      paytabs_checked: false,
      form: {
        email: "",
        password: "",
      },
      loginButtonText: this.$t('submit'),
      disableLogin: false,
      error: false,
      errorMessage: "",
      loading:false,
      show_recharge_error:false,
      recharge_error_msg:'',
      state:1,
      noNavInFooter:true,
      
    };
  },
  methods: {
    showRechargeLogin(flag){
      if(flag===2) {
        this.loginform=false;
        this.rechargeComponent=true;
      }
      else {
        this.loginform=true;
        this.rechargeComponent=false;
      }
    },

    async handleLogin() {
      this.loading=true;
      this.error=false;

      try {
        await services.login(this.form)
        // await services.getUserData()

        if (getCookie('user_id') === null) { throw new Error(this.$t("faild_login_msg")); }

        this.$router.push({ name: 'Home', params: { firstLogin: true } });

      } catch (err) {
          this.handleFailure(err);
      }
    },

    handleFailure(err) {
      // console.log(err.message)

      let msg = err.message;
      let desc = "error";
      let code = -9999;

      if (err?.isAxiosError){
        msg = err?.response?.data?.Msg;
        desc = err?.response?.data?.DESC;
        code = err?.response?.data?.CODE;
      }

      if(err instanceof LockoutError) {
        this.handleLockout(err);
      }

      if( err instanceof AuthenticationError) {
        msg = err.response.Msg;
        desc = err.response.DESC;
        code = err.response.CODE;
      }

      this.error = true;
      this.loading = false;
      this.errorMessage = msg;
      this.errorTitle = desc;

    },

    handleLockout(err) {
      let minutes = 5;
      let seconds = 0;
      let totalSeconds = 300;

      if(err.response.Data.expires_in) {
        totalSeconds = Number(err.response.Data.expires_in);
        minutes = Math.floor( totalSeconds / 60);
        seconds = totalSeconds -  (minutes * 60) ;
      }

      let intervalId = setInterval(() => {
        let time = minutes + ':' + (seconds < 10 ? "0" + seconds : seconds);
        this.loginButtonText = time;

        if(seconds == 0) {
          minutes-= 1; 
          seconds = 60;
        }
        seconds-= 1 ;

      }, 1000);

      this.disableLogin = true; 
      setTimeout(() => {
        clearInterval(intervalId);
        this.loginButtonText = this.$t('submit');
        this.disableLogin = false;
        }, totalSeconds * 1000)
      
    },

    enterEvent(e){
        this.handleLogin();
    },

  },
  created() {
    
    if(this.$route.query.status==0){
      this.show_recharge_error=true;
      this.state=3;
      this.recharge_error_msg=this.$t('canceled_recharge_request');
      // setTimeout( () => ( this.show_recharge_error= 0), 8000);
          
    }
   else if(this.$route.query.invalid_request || this.$route.query.message){
      this.show_recharge_error=true;
      this.state=2;
      this.recharge_error_msg=
      this.$route.query.message?  this.$route.query.message=='failure'  ?this.$t("your_transaction")+" "+this.$t("number")+" "+this.$route.query.order_id +" "+this.$t('was') +" "+ this.$t("failed") : this.$route.query.message
      :this.$t("invalid_recharge_request");
      // setTimeout( () => ( this.show_recharge_error= 0), 8000);
            
    }
      services.getCSRFToken(this.lang)
      .catch(err => {
        this.handleFailure(err);
      })
  }
 
};
</script>

<style scoped>
@import url("../assets/styles/form.css");
/deep/ #resetModal___BV_modal_content_{
  width: 536px;
  height: 333px;
  margin:auto;
  margin-top:30%;


}
.reset-pop-up{
  text-align: center;
  padding: 8px 0px 48px 0px;
  margin-top:-16px;
}
.reset-pop-up .phone-icon{
  height: 80px;
  width: 80px;
  background-image:url("../assets/Phone-icon.svg") ;
  margin:auto;
  margin-bottom: 16px;
}
.reset-pop-up p,.reset-pop-up a{
  font-size: 20px;
  font-weight: 600 !important;
  color: #262338;
  text-decoration: none;
}
.reset-pop-up p:nth-child(3){
  font-size: 24px;
}
.row{
  position:relative;
  margin-right:0px !important;
  margin-left:0px !important;
 
}

.colored-rectangle{
  width: 100%;
  height: 36vh;
  background: var(--secondary-color);
  top: 0;
}

.login{
   background: #f0f0f0;
}

.login-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: -235px;
    padding:0px 20px;
  }

.card{
  position: relative;
  width: 648px;
  /* min-height: 424px; */
  padding: 20px 24px;
  border: 0px;
 }

@media (max-height:700px) {

  .card{
    margin-top:25px;
  }
}

.tabsss{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 24px; 
    margin: 0px;
}

.tabsss div{
  width: 50%;
  text-align: center;
  cursor:pointer;
  padding-bottom: 15px;
  border-bottom: 2px solid #f0f0f0;
  color: #AAAAB2;
  
}

.tabsss div span {
  font-weight: 500 !important;
  font-size: 20px;
}

.tabsss .clicked{
  border-bottom: 2px solid var(--secondary-color);
  color: var(--secondary-color);
}

.tabsss .clicked span {
  font-weight: 600 !important;
  font-size: 20px;
}
.noFastRecharge{
  text-align: start !important;
  border: 0px !important;
  padding: 0px !important;
}
.noFastRecharge span{
  font-size: 24px !important;
  color: #2C3E50;
}
.forgot-pass{
  height: 20px;
  color:#2C3E50 !important;
  text-decoration: underline;
  font-weight: 500 !important;
}

@media (max-width:675px) {
  .tabsss span{
    font-size: 16px;
  }
  /deep/ #resetModal___BV_modal_content_{
    width:90%;
  }
  .reset-pop-up p,.reset-pop-up a{
    font-size: 16px;
  }
}

@media (max-width:500px) {
  .tabsss span{
    font-size: 13px;
  }

}


@media (max-width:380px) {
.tabsss span{
    font-size: 12px;
  }
}
.info-nofication{
  z-index:5;
  position:fixed ;
  top: 10% ;
  left:2%;
  width:30%;
}
</style>