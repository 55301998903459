<template>
  <div :class="{'height': lang==1}">
    <b-button
      id="inquiry-complaint-button"
      v-b-modal.modal-lg-in
      class="inquiry-btn "
      :class="{ 'nav-font-size': lang ==2 }"
      >{{ $t("complaint") }} / <label>{{ $t("inquiry") }}</label></b-button
    >
    <b-modal
      no-close-on-esc
      class="model-example inquiry"
      id="modal-lg-in"
      size="lg"
      @hidden="reset"
      :header-class="{
        'right-close': lang == 1,
        'left-close': lang == 2,
      }"
    >
      <form
        @submit.prevent="handleComplaint"
        :class="{ rtl: lang == 1, ltr: lang == 2 }"
      >

      <b-form-group style="margin-bottom:0px">
          <div class="d-flex w-100 align-items-center justify-content-between" style="margin-bottom:16px;">
            <div  class="radio-container" >
              <input
                type="radio"
                id="complaint"
                name="type"
                :checked="complaint_checked"
                @change="changeType(1)"
              />
              <label for="complaint" class="label" :class="{ 'active': complaint_checked == true }">{{ $t("complaint") }}</label>
            </div>

            <div  class="radio-container">
              <input
                type="radio"
                id="inquiry"
                name="type"
                :checked="inquiry_checked"
                @change="changeType(2)"
              />
              <label for="inquiry" class="label" :class="{ 'active': inquiry_checked == true }"> {{ $t("inquiry") }}</label>
            </div>
          </div>
        </b-form-group>
        
        <b-form-group
        id="input-group-0"
          :label="$t('meter_no')"
          label-for="meter-code"
        >
        

          <b-form-select
            id="meter-code"
            class="w-100"
            :options="mdm_meters"
            :value="codes"
            v-model="formData.req_pk"
            :state="validation.req_pk"
            aria-describedby="meter-code-feedback"
          ></b-form-select>

          <b-form-invalid-feedback  id="meter-code-feedback">
           {{ $t("required_meter") }}
          </b-form-invalid-feedback>


        </b-form-group>
        

        <b-form-group
          id="input-group-1"
          :label="$t('subject')"
          label-for="subject"
          :state="nameState"
        >
          <b-form-select
            class="w-100"
            id="subject"
            :options="options"
            :value="null"
            v-model="formData.subject"
            :state="validation.subject"
            aria-describedby="subject-feedback"
          >
          </b-form-select>

          <b-form-invalid-feedback id="subject-feedback">
            {{ $t("subject_required") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="$t('description')"
          label-for="complaint-message"
        >
          <b-form-textarea
            id="complaint-message"
            class="mb-2 mr-sm-4 mb-sm-0"
            :options="[{ text: 'Choose Meter', value: null }, '...']"
            :value="null"
            v-model="formData.description"
            :state="validation.description"
            aria-describedby="description-feedback"
          ></b-form-textarea>

          <b-form-invalid-feedback
           id="description-feedback"
          >
           {{ $t("desc_required") }}
          </b-form-invalid-feedback>

          
        </b-form-group>

        <div class="sendButton">
          <button class="w-100 btn btn-primary mb-2" id="complaint-submit-button" style="text-align: center">
            {{ $t("send") }}
          </button>
        </div>
      </form>

      <feedback
        v-if="showFeedback.status"
        class="mx-2 notification"
        :state="showFeedback.status"
        :message="showFeedback.message"
        :lang="lang"
        @unmountFeedComponent="showFeedback.status=false"
      />


    </b-modal>
  </div>
</template>

<script>
import complaintServices from "../../services/assignComplaint";
import complaintsServices from "../../services/complaintsAndInquiries";
import downImage from "../../assets/down.png";
import Feedback from "@/components/Feedback.vue";

export default {
  components: {
    Feedback,
  },
  props:["meterProps","lang"],
  data() {
    return {
      downImage: downImage,
      complaint_checked: true,
      inquiry_checked: false,
      nameState: null,
      submittedNames: [],
      codes: [],
      mdm_meters: [
        {
          text: "mdm_meters",
          value: "codes",
        },
      ],

      complaints: [],

      inquiry: [
        {
          text: "How do I check my meter readings?",
          value: 1,
        },
        {
          text: "When should I submit my meter readings?",
          value: 2,
        },
      ],
      options: [],
      formData: {
        req_pk: "",
        subject: "",
        description: "",
      },
      validation: {
        req_pk: null,
        subject: null,
        description: null,
      },
      showFeedback: {
        status: false,
        message: "",
      },
    };
  },
  methods: {
    changeType(type) {
      this.reset();
      if (type == 1) {
        this.complaint_checked = true;
        this.inquiry_checked = false;
        this.options = this.complaints;
      } else {
        this.complaint_checked = false;
        this.inquiry_checked = true;
        if(this.inquiry.length==0){
          this.options=[this.$t('no_inquiries_found')];
        }
        else this.options = this.inquiry;
      }

      //shows what will be appears in subject field here by assigning type and lang
    },
    handleComplaint() {
      if (this.validateForm()) {
        // this.formData.complaint_type  = this.complaint_checked ? 1 : 2;
        complaintServices
          .addComplaint(this.formData)
          .then((data) => {
            this.showFeedback.status = true;
            let x = this.inquiry_checked == true
                  ? this.$t("your_inquiry")
                  : this.$t("your_complaint");
            this.showFeedback.message = x + ` ${this.$t("registered_success")}\n
                                        ${this.$t("case_number")} ${data.complaint_id}`;

            setTimeout(() => (this.reset()), 1000);
          })
          .catch((err) => {
            this.showFeedback.status = 2;
            this.showFeedback.message =err.message;
            this.validation.description=false;
          });
      }
    },

    validateForm() {
      return (
        this.validateMDM_Meters() &&
        this.validateSubject() && 
        this.validateDesc() == null
      );
    },

    validateMDM_Meters() {
      this.validation.req_pk =
        this.formData.req_pk != "" && this.formData.req_pk != null;
      return this.validation;
    },

    validateSubject() {
      this.validation.subject =
        this.formData.subject != "" && this.formData.subject != null;
      return this.validation.subject;
    },

    validateDesc() {
      this.validation.description =
        this.formData.description != "" && this.formData.description != null;
      this.validation.description = this.validation.description==false? this.validation.description :null;

      return this.validation.description;
    },
    reset() {
      this.complaint_checked = true;
      this.inquiry_checked = false;
      this.options = this.complaints;
      this.formData.subject = "";
      this.formData.description = "";
      this.validation.description = null;
      this.validation.subject = null;
      this.validation.req_pk = null;
    },
  },
  watch:{
    meterProps(){
    let data= this.meterProps.map((meter) => {
      var optionText= meter.code? meter.code:meter.account_reference;
          return {
            text:
              (meter.mdm_utilities == 1
                ? this.$t("electricity")
                : meter.mdm_utilities == 2
                ? this.$t("gas")
                : this.$t("water")) +
              " - " +
            optionText,
            value: meter.req_pk,
          };
        })
        this.mdm_meters = data;
        this.formData.req_pk = data[0].value;

    complaintsServices.getComplaints().then((data) => {
      this.complaints = data.map((complaint) => {
        return {
          text: complaint.text,
          value: complaint.pk,
        };
      });
      this.options = this.complaints;
    }).catch(err=>{
       this.options=[this.$t('no_complaints_found')];
    })
    ;

    complaintsServices.getInquiries().then((data) => {
      this.inquiry = data.map((inquiry) => {
        return {
          text: inquiry.text,
          value: inquiry.pk,
        };
      });
    })
    .catch(err=>{
       this.inquiry =[];
    })
    ;
    }
  },
  
};
</script>

<style scoped>
  .height{
    height: 42px;
    margin-top: 3px;

  }
.notification {
  position: fixed;
  top: 10%;
  left: 5%;
  width:300px;
}
@media (max-width:500px) {
  .notification {

  width:200px;
}
}


.sendButton {
  padding-top: 32px;
  /* margin-bottom: 50px; */
}
.sendButton button{
border-color: var(--main-color);
}
#modal-lg-in___BV_modal_content_ {
  width: 90% !important;
  margin-left: 0 !important;
  left:20px;
  padding: 10px 20px 20px !important;
}
#modal-lg-in___BV_modal_body_{
  max-width: unset;
}
#modal-lg-in___BV_modal_footer_{
  display: none !important;
}
.modal-content {
  overflow-y: auto !important ;
}
.modal-footer {
  display: none !important;
}
.nav-font-size {
  font-size: 15px;
}
.inquiry-btn{
  margin-bottom: 0px !important;
  height: 40px;
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  background: transparent;
  border: 0;
  position: relative;
  white-space: nowrap;

}

@media (max-width:1100px){
  .inquiry-btn{
 font-size:14px;

}
}

input[type="radio"]{
  display: none;
}
.radio-container{
  width:49%;
  
 
 
}
.radio-container .label{
  width:100%;
  height: 40px;
  text-align:center;
  cursor: pointer;
  border: 1px solid #AAAAB3;
  border-radius: 4px;
  padding: 8px;
  font-weight: 500 !important;
  font-size: 16px;
  color: #4F4D61;
  line-height: 1.3;

}
.radio-container .label.active{
  border: 2px solid var(--main-color);
  position: relative;
}
.radio-container .label.active:before{
position: absolute;
height: 100%;
width:100%;
content:'';
top:0;
left:0;
background: var(--main-color);
opacity: 0.1;
}
</style>
